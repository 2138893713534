/* eslint-disable eqeqeq */
/* eslint-disable eqeqeq */
<template>
  <b-container fluid class="content-wrapper">
    <div id="qr-form" v-if="!qrGenerated">
      <b-row>
        <h1  class="title">Generador de QR</h1>
      </b-row>

      <b-form @submit="onSubmit">
        <b-row>
          <b-col lg="4">
            <b-form-group
              :invalid-feedback="invalidQrQuantityFeedback"
              :state="formValidation.quantity"
              label="Número de codigos a generar"
            >
              <b-form-input
                id="amount"
                v-model="form.quantity"
                type="number"
                class="col-4"
                :state="formValidation.quantity"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="!isPartner">
          <b-col lg="4">
            <b-form-group
              label="Caducidad"
              :invalid-feedback="invalidExpirationFeedback"
              :state="formValidation.expiration"
            >
              <b-form-datepicker
                id="expiration"
                v-model="form.expiration"
                locale="es"
                :state="formValidation.expiration"
                placeholder=""
                :start-weekday="1"
              >
              </b-form-datepicker>
            </b-form-group>
          </b-col>

          <b-col lg="4" class="margin-top">
            <b-form-group
              label="Días de validez"
              :invalid-feedback="invalidValidDaysFeedback"
              :state="formValidation.validDays"
            >
              <b-form-checkbox-group
                v-model="form.validDays"
                id="valid-days"
                :options="weekDays"
              >
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="!isPartner">
          <b-col lg="4">
              <b-form-group
              label="Hora inicial de validez"
              :invalid-feedback="invalidStartTimeFeedback"
              :state="formValidation.startTime"
            >
              <b-form-timepicker
                id="start-time"
                v-model="form.startTime"
                :state="formValidation.startTime"
                no-close-button
                placeholder=""
                locale="es"
              ></b-form-timepicker>
              </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group
              label="Hora final de validez"
              :invalid-feedback="invalidEndTimeFeedback"
              :state="formValidation.endTime"
            >
              <b-form-timepicker
                id="end-time"
                v-model="form.endTime"
                :state="formValidation.endTime"
                locale="es"
                placeholder=""
                no-close-button
              ></b-form-timepicker>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row >
          <b-col lg="4" v-if="!isPartner">
            <b-form-group>
              <label>Para que máquinas es válido el QR? </label>

              <b-form-checkbox
                v-if="hasBoxes"
                id="boxes"
                v-model="form.validMachines"
                name="boxes"
                value="boxes"
                @change="handleMachines()"
              > Boxes
              </b-form-checkbox>

              <b-form-checkbox
                v-if="hasVacuums"
                id="vacuum"
                v-model="form.validMachines"
                name="vacuum"
                value="vacuums"
                @change="handleMachines()"
              > Aspiradoras
              </b-form-checkbox>

              <b-form-checkbox
                v-if="hasDogs"
                id="dogs"
                v-model="form.validMachines"
                name="dogs"
                value="dogs"
                @change="handleMachines()"
              > DipDog
              </b-form-checkbox>
            </b-form-group>

            <b-col v-if='showBoxSelection' class="pt-2" >
              <b-form-group>
                <b-form-checkbox
                  id="all-boxes"
                  v-model="form.allBoxes"
                  name="all-boxes"
                  value="true"
                  @change="handleBoxes()"
                > Válido en todos los boxes
                </b-form-checkbox>
              </b-form-group>

              <div class="d-flex" v-if="!form.allBoxes">
                <label for="box" class="col-auto pl-0">Número de box </label>

                <b-form-input
                  id="boxes"
                  v-model="form.box"
                  type="number"
                  class="col-3"
                ></b-form-input>
                </div>
            </b-col>
          </b-col>

          <b-col lg="4" class="margin-top">
              <b-form-group
                :invalid-feedback="invalidAmountFeedback"
                :state="formValidation.amount"
              >
                <label for="box" class="col-auto pl-0">Importe del código en CÉNTIMOS. Mín, 10 </label>

                <b-form-input
                  id="amount"
                  v-model="form.amount"
                  :state="formValidation.amount"
                  type="number"
                  min="10"
                  max="1000"
                  class="col-4"
                ></b-form-input>
              </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="!isPartner">
          <b-col lg="8">
            <b-form-group>
              <b-form-checkbox
                id="all-stations"
                v-model="form.allStations"
                name="all-stations"
                value="true"
              > Válido en todos los centros de lavado
              </b-form-checkbox>
            </b-form-group>

            <div class="d-flex" v-if="!form.allStations">
              <label for="stations" class="col-auto pl-0">Selecciona centro de lavado </label>
                <b-form-checkbox-group
                v-model="form.stationCodes"
                id="station-ids"
                :options="stations"
              >
              </b-form-checkbox-group>
              </div>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col lg="8" class="d-flex justify-content-end">
            <b-button type="submit" variant="primary" :disabled="formIsInvalid" >
              <b-spinner small v-if="loading"></b-spinner>
               {{ buttonText }}
              </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>

    <div id="show-qr" v-else>
      <div v-if="form.quantity === 1">
         <b-row class="mt-6 mb-3 d-flex justify-content-center">
            <img class="qr-img" :src="`data:image/png;base64,${base64QrCode}`" />
         </b-row>

         <b-row class="mt-6 mb-3 d-flex justify-content-center">
          <div class="buttons">
             <b-button variant="primary" @click="downloadQR">
                <b-spinner  v-if="loading" small></b-spinner>
                <b-icon v-else icon="cloud-download" aria-hidden="true"></b-icon>
            </b-button>
            <a class="btn btn-primary" :href="`https://wa.me/?text=${imgPath}`" target="_blank">
              <img class="share-icon" src="@/assets/whatsapp.svg" alt="whatsapp" />
            </a>
            <a  class="btn btn-primary" :href="`mailto:email@domain.com?body=${imgPath}`">
              <b-icon icon="envelope" aria-hidden="true"></b-icon>
            </a>
          </div>
          <div class="buttons">
            <b-button class="another-button" variant="primary" @click="toggleQR">
                Generar otro QR
            </b-button>
          </div>
        </b-row>
      </div>

      <div v-else>
        <b-row class="mt-10 mb-3 d-flex justify-content-center">
           Los códigos QR se han generado correctamente
        </b-row>

        <div class="buttons">
            <a class="btn btn-primary" :href=zipPath download>
              <b-spinner  v-if="loading" small></b-spinner>
                <b-icon v-else icon="cloud-download" aria-hidden="true"></b-icon> Zip
            </a>

            <b-button class="another-button" variant="primary" @click="toggleQR">
                Generar más códigos
            </b-button>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import Config from '../config/config'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'qr-generate',
  data () {
    return {
      qrGenerated: false,
      base64QrCode: '',
      imgFile: '',
      imgPath: '',
      zipFile: '',
      zipPath: '',
      loading: false,
      form: {
        allBoxes: true,
        allStations: true,
        box: 0,
        amount: 50,
        validDays: [0, 1, 2, 3, 4, 5, 6],
        stationCodes: [],
        expiration: null,
        startTime: '00:00:00',
        endTime: '23:59:59',
        quantity: 1,
        validMachines: ['boxes', 'vacuums', 'dogs']
      },
      tempFormValidDays: [],
      weekDays: [
        { text: 'Lunes', value: '0' },
        { text: 'Martes', value: '1' },
        { text: 'Mércoles', value: '2' },
        { text: 'Jueves', value: '3' },
        { text: 'Viernes', value: '4' },
        { text: 'Sábado', value: '5' },
        { text: 'Domingo', value: '6' }
      ],
      stations: [
        // { text: 'Station1', value: 12 },
        // { text: 'Station2', value: 33 },
        // { text: 'Station3', value: 55 },
        // { text: 'Station4', value: 76 },
        // { text: 'Station5', value: 78 }
      ],
      // userStationCodes: [],
      formValidation: {
        expiration: true,
        validDays: true,
        startTime: true,
        endTime: true,
        amount: true,
        quantity: null
      },
      boxesNum: 0,
      dogsNum: 0,
      vacuumsNum: 0
    }
  },
  computed: {
    ...mapGetters({
      userStations: 'auth/userStations',
      user: 'auth/user'
    }),
    isPartner () {
      return this.user && this.user.role === 'partner'
    },
    buttonText () {
      return this.loading
        ? 'Generando...'
        : this.form.quantity > 1 ? 'Generar códigos' : 'Generar código'
    },
    invalidExpirationFeedback () {
      return 'Selecciona una fecha'
    },
    invalidValidDaysFeedback () {
      return 'Selelcciona los días en que el QR es válido'
    },
    invalidStartTimeFeedback () {
      return 'Selecciona una hora'
    },
    invalidEndTimeFeedback () {
      return 'Selecciona una hora'
    },
    invalidAmountFeedback () {
      return 'Entra un importe mayor que 0'
    },
    invalidQrQuantityFeedback () {
      return 'Maximo 50'
    },
    formIsInvalid () {
      return Object.values(this.formValidation).indexOf(false) > -1 ||
         Object.values(this.formValidation).indexOf(null) > -1
    },
    showBoxSelection () {
      return this.form.validMachines.length === 1 && this.form.validMachines.includes('boxes')
    },
    hasBoxes () {
      return this.boxesNum > 0
    },
    hasDogs () {
      return this.dogsNum > 0
    },
    hasVacuums () {
      return this.vacuumsNum > 0
    }
  },
  watch: {
    'form.expiration': function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.isExpirationValid()
      }
    },
    'form.validDays': function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.isValidDaysValid()
      }
    },
    'form.startTime': function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.isStartTimeValid()
      }
    },
    'form.endTime': function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.isEndTimeValid()
      }
    },
    'form.quantity': function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.isQrQuantityValid()
      }
    },
    'form.amount': function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.isAmountValid()
      }
    },
    userStations: function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.setStations()
      }
    }
  },
  methods: {
    isExpirationValid () {
      this.formValidation.expiration = this.form.expiration != null
    },
    isValidDaysValid () {
      this.formValidation.validDays = this.form.validDays.length > 0
    },
    isStartTimeValid () {
      this.formValidation.startTime = this.form.startTime != null
      // return this.form.expiration != this.formDefault.expiration && this.form.expiration != ''
    },
    isEndTimeValid () {
      this.formValidation.endTime = this.form.endTime != null
    },
    isAmountValid () {
      this.formValidation.amount = this.form.amount > 0
    },
    isQrQuantityValid () {
      this.formValidation.quantity = this.form.quantity > 0 && this.form.quantity < 51
    },
    createValidDaysArray () {
      // console.log('form valid days', this.form.validDays)
      this.tempFormValidDays = [...this.form.validDays]
      const validDays = [0, 0, 0, 0, 0, 0, 0]
      this.form.validDays.forEach(element => {
        validDays[element] = 1
      })
      this.form.validDays = [...validDays]
    },
    createValidStationCodesArray () {
      const stationCodes = [0, 0, 0]

      this.form.stationCodes.forEach((element, index) => {
        stationCodes[index] = element
      })

      this.form.stationCodes = [...stationCodes]
    },
    setDefaultExpiration () {
      var today = new Date()
      if (this.isPartner) {
        today.setDate(today.getDate() + 365)
        this.form.expiration = today
      } else {
        today.setDate(today.getDate() + 30)
        this.form.expiration = today
      }
    },
    setStations () {
      if (this.userStations) {
        this.userStations.forEach(station => {
          this.stations.push({
            text: station.station_name,
            value: station.code
          })
        })

        this.setAvailableMachines()
      }

      return this.formStations
    },
    onSubmit (event) {
      event.preventDefault()
      this.isExpirationValid()
      this.isValidDaysValid()
      this.isStartTimeValid()
      this.isEndTimeValid()
      this.isQrQuantityValid()
      this.isAmountValid()
      this.loading = true

      if (Object.values(this.formValidation).indexOf(false) > -1) {
        // console.log("invalid")
      } else {
        this.formIsValid = true
        this.createValidStationCodesArray()
        // this.handleBoxNum()
        this.handleMachines()
        this.createValidDaysArray()

        axios
          .post(`${Config.API_BASE_URL}/qr/generate`, this.form)
          .then((response) => {
            this.form.validDays = [...this.tempFormValidDays]
            if (this.form.quantity === 1) {
              this.base64QrCode = response.data[0]
              this.imgFile = response.data[1]
              this.imgPath = `${Config.BASE_URL}/temp/${response.data[1]}`
            } else {
              this.zipFile = response.data
              this.zipPath = `${Config.BASE_URL}/${this.zipFile}`
              this.loading = false
            }
            this.qrGenerated = true
            this.loading = false
          })
      }
    },
    handleBoxes () {
      // condition should be false because it is evaluated before checking the checkbox
      if (this.form.allBoxes === false) {
        this.form.box = 0
      }
    },
    handleBoxNum () {
      // boxes machines will have the formaat 1X
      this.form.machine = parseInt('1' + this.form.box.toString())
    },
    handleMachines () {
      if (this.form.validMachines.length === 3 || this.form.validMachines.length < 1) {
        this.form.machine = 99 // all
      } else if (this.form.validMachines.length === 2) {
        // box + vacuum -> 91
        // box + dog -> 92
        // dog + vacuum => 93
        if (this.form.validMachines.includes('boxes') && this.form.validMachines.includes('vacuums')) {
          this.form.machine = 91
        } else if (this.form.validMachines.includes('boxes') && this.form.validMachines.includes('dogs')) {
          this.form.machine = 92
        } else if (this.form.validMachines.includes('vacuums') && this.form.validMachines.includes('dogs')) {
          this.form.machine = 93
        }
      } else {
        if (this.form.validMachines.includes('vacuums')) {
          this.form.machine = 20 // do not allow to chooose specific vacuums
        } else if (this.form.validMachines.includes('dogs')) {
          this.form.machine = 30 // do not allow to chooose specific dog machines
        } else if (this.form.validMachines.includes('boxes')) {
          // this.form.machine = 10 // all boxes by default
          this.handleBoxNum()
        }
      }
    },
    toggleQR () {
      this.qrGenerated = false
      this.loading = false
    },
    downloadQR () {
      this.loading = true
      axios({
        url: `${Config.API_BASE_URL}/download/${this.imgFile}`,
        method: 'GET',
        responseType: 'blob' // important
      }).then((response) => {
        const type = response.headers['application/pdf']
        const blob = new Blob([response.data], { type: type })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = this.imgFile
        link.click()
        this.loading = false
      })
        .catch(() => console.log('error occured'))
    },
    sendWhatApp () {
      var fakeLink = document.createElement('a')
      fakeLink.setAttribute('href', 'https://wa.me/?text=' + encodeURIComponent(this.imgPath))

      fakeLink.click()
    },
    setAvailableMachines () {
      this.userStations.forEach(station => {
        // the higest box number is a user stations
        this.boxesNum = station.qr_boxes_num > this.boxesNum ? station.qr_boxes_num : this.boxesNum
        this.dogsNum = station.qr_dogs_num > this.dogsNum ? station.qr_dogs_num : this.dogsNum
        this.vacuumsNum = station.qr_vacuums_num > this.vacuumsNum ? station.qr_vacuums_num : this.vacuumsNum
      })
    }
  },
  mounted () {
    this.isQrQuantityValid()
    this.setDefaultExpiration()
    this.setStations()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

#qr-form {
  .row {
    margin-bottom: 10px;

    @include tablet {
      margin-bottom: 30px;
    }
  }
}

.custom-check-boxes {
  display: flex;
  flex-wrap: wrap;
 // ::v-deep input {margin: 5px !important; }
}

#boxes {
  width: 100px;
}

::v-deep input, button, select, optgroup, textarea {
    margin: 5px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

// ::v-deep input[type=checkbox]:checked:after {
//     content: "\2714";
//     background-color: red;
//  }

.margin-top {
  margin-top:30px;

  @include tablet {
   margin-top:0
  }
}

#show-qr {
  margin-top: 30px;
  .qr-img {
    max-width: 150px;
    height: auto;
    //padding: 20px;
  }

  .share-icon {
    height: 23px;
    width: auto;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 12px;

  .btn {
   margin: 20px;
  }
}

.another-button.btn-primary {
  border-color: $primary;
  color: $primary!important;
  background-color: #fff;
  // margin-top: 20px;
}

</style>
