<template>
  <div class="qr-monedero-user-form">
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col lg="6">
          <b-form-group
            label="Nombre"
          >
            <b-form-input
              v-model="form.name"
              type="text"
              placeholder="Nombre completo"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group
            label="Email"
          >
            <b-form-input
              v-model="form.email"
              type="email"
              placeholder="ejemplo@email.com"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group
            label="Teléfono"
          >
            <b-form-input
              v-model="form.phone"
              type="tel"
              placeholder="Teléfono de contacto"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group
            label="Código Postal"
          >
            <b-form-input
              v-model="form.zipCode"
              type="text"
              placeholder="Código postal"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12">
          <b-form-group
            label="Dirección"
          >
            <b-form-input
              v-model="form.address"
              type="text"
              placeholder="Dirección completa"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group
            label="Localidad"
          >
            <b-form-input
              v-model="form.location"
              type="text"
              placeholder="Localidad"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
              v-model="form.hasCompany"
              name="hasCompany"
              @change="handleHasCompanyChange"
            >
              ¿El nuevo usuario pertenece a una empresa?
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="form.hasCompany" class="mt-2">
        <b-col v-if="!form.createNewCompany" cols="12">
          <b-form-group
            label="Empresa"
            label-for="company"
          >
            <b-form-select
              id="company"
              v-model="form.qr_monedero_company_id"
              :options="companies"
              value-field="id"
              text-field="name"
              @input="handleCompanySelection"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <b-form-checkbox
              v-model="form.createNewCompany"
              name="createNewCompany"
            >
              Necesito crear nueva empresa
            </b-form-checkbox>
          </b-form-group>

          <b-row v-if="form.createNewCompany">
            <b-col cols="12">
              Crea el usuario y luego podrás crear la empresa
            </b-col>
          </b-row>
       </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col class="d-flex justify-content-end">
          <b-button type="submit" variant="primary" :disabled="formDisabled" >
            {{ submitButtonText }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios'
import Config from '@/config/config'
import { mapGetters } from 'vuex'

export default {
  name: 'QrMonederoUserForm',
  props: {
    mode: {
      type: String,
      default: 'create',
      validator: value => ['create', 'update'].includes(value),
      submited: false
    },
    initialData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        zipCode: '',
        address: '',
        location: '',
        hasCompany: false,
        createNewCompany: false,
        qr_monedero_company_id: null
      },
      companies: [],
      errors: {},
      submited: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    submitButtonText () {
      return this.form.id ? 'Editar Usuario' : 'Crear Usuario'
    },
    formDisabled () {
      return this.submited
    }
  },
  methods: {
    validateForm () {
      return true
    },
    onSubmit () {
      this.$emit('submit', this.form)
      this.submited = true
    },
    handleCompanySelection () {
      axios.get(`${Config.API_BASE_URL}/qr-monedero/companies/${this.form.qr_monedero_company_id}`).then((response) => {
        this.$store.dispatch('monedero/SET_QR_MONEDERO_SELECTED_COMPANY', response.data)
      })
    },
    handleHasCompanyChange (value) {
      // store the user in the store to be used in the next step
      const QrMonederoUser = {
        name: this.form.name,
        email: this.form.email,
        phone: this.form.phone,
        zipCode: this.form.zipCode,
        address: this.form.address,
        location: this.form.location
      }
      this.$store.dispatch('monedero/SET_QR_MONEDERO_USER', QrMonederoUser)

      if (!value) {
        this.form.createNewCompany = false
        this.form.qr_monedero_company_id = null
      }
      this.loadCompanies()
    },
    async loadCompanies () {
      try {
        const response = await axios.get(`${Config.API_BASE_URL}/qr-monedero/companies/user/${this.user.id}`)
        console.log('Companies response:', response)

        if (!response.data) {
          throw new Error('No data received from server')
        }

        this.companies = (Array.isArray(response.data) ? response.data : []).map(company => ({
          id: company.id,
          name: company.name || ''
        }))
      } catch (error) {
        console.error('Error loading companies:', error)
        this.$bvToast.toast('Error al cargar empresas', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }
    }
  },
  created () {
    if (this.initialData) {
      Object.assign(this.form, this.initialData)
      if (this.form.qr_monedero_company_id) {
        this.form.hasCompany = true
      }
    }
  }
}
</script>

<style scoped>
.qr-monedero-user-form {
  margin-top: 1rem;
}
</style>
