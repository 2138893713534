/* eslint-disable eqeqeq */
/* eslint-disable eqeqeq */
<template>
  <b-container fluid class="content-wrapper">
    <div id="qr-form" v-if="!showTable">
      <b-row>
        <h1  class="title">Recaudación QR Monedero</h1>
      </b-row>

      <b-form @submit="onSubmit" v-if="hasStations">
        <b-row>
          <b-col lg="4">
            <b-form-group label="Selecciona un periodo">
              <b-form-select v-model="form.period">
                <b-form-select-option value="today">Hoy</b-form-select-option>
                <b-form-select-option value="this-week">Esta semana</b-form-select-option>
                <b-form-select-option value="this-month">Este mes</b-form-select-option>
                <b-form-select-option value="this-year">Este año</b-form-select-option>
                <b-form-select-option value="custom">Entre fechas</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="form.period === 'custom'">
          <b-col lg="4">
              <b-form-group
              label="Fecha inicial"
              :invalid-feedback="invalidStartDateFeedback"
              :state="formValidation.startDate"
            >
              <b-form-datepicker
                id="start-time"
                v-model="form.startDate"
                :state="formValidation.startDate"
                no-close-button
                placeholder=""
                locale="es"
                :start-weekday="1"
              ></b-form-datepicker>
              </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group
              label="Fecha Final"
              :invalid-feedback="invalidEndDateFeedback"
              :state="formValidation.endDate"
            >
              <b-form-datepicker
                id="end-time"
                v-model="form.endDate"
                :state="formValidation.endDate"
                locale="es"
                placeholder=""
                no-close-button
                :start-weekday="1"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>

        <b-col lg="8">
          <div class="d-flex" v-if="hasMultipleStations" >
            <label for="stations" class="col-auto pl-0">Selecciona centro de lavado </label>
              <b-form-checkbox-group
              v-model="form.stationCodes"
              id="station-ids"
              :options="stations"
            >
            </b-form-checkbox-group>
          </div>
        </b-col>

        <b-row class="mt-3">
          <b-col lg="8" class="d-flex justify-content-end">
            <b-button type="submit" variant="primary" :disabled="formIsInvalid" >
              <b-spinner small v-if="loading"></b-spinner>
               {{ buttonText }}
              </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>

    <div id="show-data" v-else>
         <b-row class="mt-6 mb-3 d-flex justify-content-center">
          <div class="buttons">
             <div class="excel">
                <vue-excel-xlsx
                    :data="transactions"
                    :columns="excelColumns"
                    :file-name="'qr-monedero-recaudacion'"
                    :file-type="'xlsx'"
                    :sheet-name="'hoja1'"
                    >
                    <b-icon icon="cloud-download" aria-hidden="true"></b-icon> Excel
               </vue-excel-xlsx>
             </div>

            <b-button class="another-button" variant="primary" @click="toggleTable">
                Hacer otra consulta
            </b-button>
          </div>
        </b-row>

        <b-row class="mt-6 mb-3 d-flex justify-content-center">
            <div id="total">
              <p> TOTAL: {{totalAmount}} € </p>
            </div>

            <b-table striped hover responsive
            :items="formattedTransactions"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            label-sort-asc=""
            label-sort-desc=""
            label-sort-clear=""
            ></b-table>

            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
              class="custompagination"
            ></b-pagination>
        </b-row>
    </div>
  </b-container>
</template>

<script>
import Config from '../config/config'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'qr-show-transactions',
  data () {
    return {
      transactions: {},
      noData: 'No hay datos para ese periodo',
      fields: [
        { key: 'id', label: 'Id' },
        { key: 'created_at', label: 'Fecha', sortable: true },
        { key: 'amount', label: 'Importe', sortable: true },
        { key: 'qr_monedero_id', label: 'QR Monedero', sortable: true }
      ],
      perPage: 50,
      currentPage: 1,
      loading: false,
      showTable: false,
      totalAmount: 0,
      form: {
        stationCodes: [],
        startDate: '',
        endDate: '',
        period: 'today'
      },
      stations: [
      ],
      formValidation: {
        startDate: true,
        endDate: true
      },
      excelColumns: [
        { field: 'id', label: 'Id' },
        { field: 'created_at', label: 'Fecha' },
        { field: 'amount', label: 'Importe' },
        { field: 'qr_monedero_id', label: 'QR Monedero' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      userStations: 'auth/userStations',
      user: 'auth/user'
    }),
    rows () {
      return this.transactions?.length ?? 0
    },
    buttonText () {
      return this.loading
        ? 'Cargando...'
        : 'Obtener datos'
    },
    invalidStartDateFeedback () {
      return 'Selecciona una fecha'
    },
    invalidEndDateFeedback () {
      return 'Selecciona una fecha'
    },
    formIsInvalid () {
      return Object.values(this.formValidation).indexOf(false) > -1 ||
         Object.values(this.formValidation).indexOf(null) > -1
    },
    hasStations () {
      return this.userStations && this.userStations.length > 0
    },
    hasMultipleStations () {
      return this.userStations && this.userStations.length > 1
    },
    formattedTransactions () {
      return this.transactions.map(transaction => {
        return {
          ...transaction,
          created_at: this.formatDateES(transaction.created_at)
        }
      })
    }
  },
  watch: {
    'form.startDate': function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.isStartDateValid()
      }
    },
    'form.endDate': function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.isEndDateValid()
      }
    },
    userStations: function (newVal, oldVal) {
      // eslint-disable-next-line eqeqeq
      if (newVal != oldVal) {
        this.setStations()
      }
    }
  },
  methods: {
    isStartDateValid () {
      this.formValidation.startDate = this.form.startDate != null
    },
    isEndDateValid () {
      this.formValidation.endDate = this.form.endDate != null
    },
    createValidStationCodesArray () {
      const stationCodes = [0, 0, 0]

      this.form.stationCodes.forEach((element, index) => {
        stationCodes[index] = element
      })
      this.form.stationCodes = [...stationCodes]
    },
    setDefaultStartDate () {
      const today = new Date()
      const oneMonthAgo = today.setDate(today.getDate() - 30)
      this.form.startDate = this.formatDateISO(oneMonthAgo)
    },
    setDefaultEndDate () {
      const today = new Date()
      today.setDate(today.getDate())
      this.form.endDate = this.formatDateISO(today)
    },
    formatDateISO (date) {
      return new Date(date).toISOString().split('T')[0]
    },
    formatDateES  (dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString('es-ES')
    },
    setStations () {
      if (this.userStations) {
        this.userStations.forEach(station => {
          this.stations.push({
            text: station.station_name,
            value: station.code
          })
        })
      }
      return this.formStations
    },
    onSubmit (event) {
      event.preventDefault()
      this.isStartDateValid()
      this.isEndDateValid()
      this.loading = true

      if (Object.values(this.formValidation).indexOf(false) > -1) {
        console.log('invalid')
      } else {
        this.formIsValid = true
        this.createValidStationCodesArray()

        const stationCode = this.userStations[0].code

        let endpoint = `${Config.API_BASE_URL}/qr-monedero/transactions/${stationCode}/${this.form.period}`

        console.log('start', this.form.startDate)
        console.log('end', this.form.endDate)

        if (this.form.period === 'custom') {
          endpoint += `/${this.form.startDate}/${this.form.endDate}`
        }

        axios
          .get(endpoint)
          .then((response) => {
            this.showTable = true
            this.loading = false
            this.transactions = response.data.transactions

            this.totalAmount = parseInt(response.data.totalAmount)
          })
      }
    },
    toggleTable () {
      this.showTable = false
      this.loading = false
    }
  },
  mounted () {
    this.setDefaultStartDate()
    this.setDefaultEndDate()
    this.setStations()
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/_variables.scss";

#qr-form {
  .row {
    margin-bottom: 10px;

    @include tablet {
      margin-bottom: 30px;
    }
  }
}

.custom-check-boxes {
  display: flex;
  flex-wrap: wrap;
 // ::v-deep input {margin: 5px !important; }
}

#boxes {
  width: 100px;
}

::v-deep input, button, select, optgroup, textarea {
    margin: 5px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.margin-top {
  margin-top:30px;

  @include tablet {
   margin-top:0
  }
}
.sub-input {
  margin-left: 15px;
  font-size: 14px
}

#total {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px
}
.buttons {
  .btn {
    display: inline-block;
    margin: 0 20px;
  }
}

.excel {
  margin-top: 20px;
  display: inline-block;

  button {
    border-color: $primary;
    color: #fff;
    background-color:  $primary!important;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    border-width: 0;
  }
}

.another-button.btn-primary {
  border-color: $primary;
  color: $primary!important;
  background-color: #fff;
}

::v-deep .custompagination .page-item.active .page-link {
  background-color: $primary!important;
  border-color: $primary!important;
}
</style>
