<template>
  <div class="qr-monedero-company-form">
    <b-row class="mt-3">
      <b-col cols="12">
        <b-form-group>
          <b-form-checkbox
            v-model="sameAsUser"
            name="sameAsUser"
            @change="handleSameAsUserToggle"
          >
            Mismos datos que el usuario
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col lg="6">
          <b-form-group
            label="Nombre"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="form.name"
              type="text"
              placeholder="Nombre de la empresa"
            />
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              type="email"
              placeholder="Email de la empresa"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-col lg="6">
          <b-form-group
            label="CIF"
            label-for="cif"
          >
            <b-form-input
              id="cif"
              v-model="form.cif"
              type="text"
              placeholder="CIF"
            />
          </b-form-group>
        </b-col>

      <b-row>
        <b-col lg="6">
          <b-form-group
            label="Teléfono"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="form.phone"
              type="tel"
              placeholder="Teléfono de la empresa"
            />
          </b-form-group>
        </b-col>

        <b-col lg="6">
          <b-form-group
            label="Dirección"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="form.address"
              type="text"
              placeholder="Dirección de la empresa"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6">
          <b-form-group
            label="Localidad"
            label-for="location"
          >
            <b-form-input
              id="location"
              v-model="form.location"
              type="text"
              placeholder="Localidad de la empresa"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group
            label="Código Postal"
          >
            <b-form-input
              v-model="form.zipCode"
              type="text"
              placeholder="Código postal"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-button
            type="submit"
            variant="primary"
            :disabled="formDisabled"
          >
            {{ submitButtonText }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'QrMonederoCompanyForm',
  props: {
    mode: {
      type: String,
      default: 'create',
      validator: value => ['create', 'update'].includes(value)
    },
    initialData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        address: '',
        location: '',
        zipCode: '',
        cif: ''
      },
      loading: false,
      submited: false,
      sameAsUser: false
    }
  },
  computed: {
    submitButtonText () {
      const action = this.mode === 'create' ? ' Crear Empresa' : 'Editar Empresa'
      return this.loading ? `${action}...` : action
    },
    formDisabled () {
      return this.submited
    }
  },
  methods: {
    onSubmit () {
      this.$emit('submit', this.form)
      this.submited = true
      this.resetQrMonederoUserState()
    },
    resetQrMonederoUserState () {
      this.$store.commit('RESET_QR_MONEDERO_USER')
    },
    handleSameAsUserToggle () {
      if (this.sameAsUser) {
        this.form.name = this.$store.state.monedero.qrMonederoUser.name
        this.form.email = this.$store.state.monedero.qrMonederoUser.email
        this.form.phone = this.$store.state.monedero.qrMonederoUser.phone
        this.form.address = this.$store.state.monedero.qrMonederoUser.address
        this.form.location = this.$store.state.monedero.qrMonederoUser.location
        this.form.zipCode = this.$store.state.monedero.qrMonederoUser.zipCode
        this.form.cif = this.$store.state.monedero.qrMonederoUser.cif
      } else {
        this.form.name = ''
        this.form.email = ''
        this.form.phone = ''
        this.form.address = ''
        this.form.location = ''
        this.form.zipCode = ''
        this.form.cif = ''
      }
    }
  },
  created () {
    if (this.initialData) {
      Object.assign(this.form, this.initialData)
    }
  }
}
</script>

<style scoped>
.qr-monedero-company-form {
  margin-top: 1rem;
}
</style>
