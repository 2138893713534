export default {
  namespaced: true,

  state: {
    qrMonederoUser: {},
    loading: false,
    qrMonederoSelectedCompany: null
  },

  getters: {
    qrMonederoUser (state) {
      return state.qrMonederoUser
    },
    qrMonederoSelectedCompany (state) {
      return state.qrMonederoSelectedCompany
    },
    qrMonederoSelecteduser  (state) {
      return state.qrMonederoSelecteduser
    },
    loading (state) {
      return state.loading
    }
  },

  mutations: {
    SET_QR_MONEDERO_USER (state, user) {
      state.qrMonederoUser = user
    },
    SET_QR_MONEDERO_SELECTED_COMPANY (state, company) {
      state.qrMonederoSelectedCompany = company
    },
    SET_QR_MONEDERO_SELECTED_USER (state, user) {
      state.qrMonederoSelectedUser = user
    },
    SET_LOADING (state, loading) {
      state.loading = loading
    }
  },

  actions: {
    SET_QR_MONEDERO_USER ({ commit }, user) {
      commit('SET_QR_MONEDERO_USER', user)
    },
    SET_QR_MONEDERO_SELECTED_COMPANY ({ commit }, company) {
      commit('SET_QR_MONEDERO_SELECTED_COMPANY', company)
    },
    SET_QR_MONEDERO_SELECTED_USER ({ commit }, user) {
      commit('SET_QR_MONEDERO_SELECTED_USER', user)
    },
    SET_LOADING ({ commit }, loading) {
      commit('SET_LOADING', loading)
    }
  }
}
