<template>
  <b-container fluid class="content-wrapper">
    <div class="qr-monedero-create">
      <h1>Crear QR Monedero</h1>

      <b-row>
        <b-col lg="6">
          <b-form-group
            v-if="!form.createNewUser"
            label="Usuario del Monedero"
          >
          <b-form-select
            v-model="form.selectedUserId"
            :options="users"
            value-field="id"
            text-field="name"
            @input="handleUserSelection"
          ></b-form-select>
          </b-form-group>

          <b-form-group class="mt-3">
            <b-form-checkbox
              v-model="form.createNewUser"
              name="create-new-user"
              @change="handleNewUserToggle"
            >
              Crear nuevo usuario
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <template v-if="form.createNewUser">
        <QrMonederoUserForm
          mode="create"
          @submit="handleUserSubmit"
        />

        <template v-if="showCompanyForm">
          <h3 class="mt-4">Información de la Empresa</h3>
          <QrMonederoCompanyForm
            mode="create"
            @submit="handleCompanySubmit"
          />
        </template>
      </template>

      <b-row v-if="showBalanceInput">
        <b-col lg="6">
          <b-form-group
            label="Saldo Inicial"
          >
            <b-form-input
              v-model="form.initialBalance"
              type="number"
              min="0"
              max="1000"
            ></b-form-input>
          </b-form-group>

          <b-form-group class="mt-3" v-if="showsharedBalance">
            <b-form-checkbox
              v-model="sharedBalance"
              name="shared-balance"
              @change="handleSharerBalanceToggle"
            >
              El saldo es compartido
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="showBalanceInput" class="mt-3">
        <b-col class="d-flex justify-content-end">
          <b-button @click="createQrMonedero" variant="primary">
            <b-spinner small v-if="loading"></b-spinner>
            {{ buttonText }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import Config from '@/config/config'
import QrMonederoUserForm from '@/components/QrMonedero/QrMonederoUserForm.vue'
import QrMonederoCompanyForm from '@/components/QrMonedero/QrMonederoCompanyForm.vue'

export default {
  name: 'QrMonederoCreate',
  components: {
    QrMonederoUserForm,
    QrMonederoCompanyForm
  },
  data () {
    return {
      loading: false,
      users: [],
      form: {
        selectedUserId: null,
        selectedUserObject: null,
        createNewUser: false,
        initialBalance: '',
        sharedBalance: false
      },
      submittedData: null,
      showCompanyForm: false,
      createdUserId: null,
      createdCompanyId: null,
      pendingUserUpdate: null,
      submittedCompany: false,
      sharedBalance: false
    }
  },
  computed: {
    ...mapGetters({
      stationUser: 'auth/user'
    }),
    buttonText () {
      return this.loading ? 'Creando...' : 'Crear QR Monedero'
    },
    doneHandleCompany () {
      return !this.showCompanyForm || this.submittedCompany
    },
    // canShowBalanceInput () {
    //   return this.form.createNewUser
    //     ? this.submittedData && this.doneHandleCompany
    //     : this.form.selectedUserId && !this.sharedBalance
    // },
    showBalanceInput () {
      const selectedUser = this.form.selectedUserObject
      const selectedCompany = this.$store.getters['monedero/qrMonederoSelectedCompany']

      console.log('selectedUser', selectedUser)
      console.log('!selectedUser?.qr_monedero_company?.shared_balance', !selectedUser?.qr_monedero_company?.shared_balance)
      console.log('!selectedCompany?.shared_balance', !selectedCompany?.shared_balance)
      console.log('slected company', selectedCompany)

      if (this.form.createNewUser) {
        return this.submittedData && this.doneHandleCompany && !selectedCompany?.shared_balance
      } else {
        return (selectedUser && !selectedUser.qr_monedero_company?.shared_balance) ||
            (selectedCompany && !selectedCompany.shared_balance)
      }
    },
    showsharedBalance () {
      return this.createdCompanyId
    },
    doesTheCompanyHaveSharedBalance () {
      return this.$store.getters['monedero/qrMonederoSelectedCompany']?.sharedBalance || this.selectedUser?.company?.sharedBalance
    }
  },
  methods: {
    async handleUserSubmit (userData) {
      userData.user_id = this.stationUser.id
      this.submittedData = userData
      this.showCompanyForm = userData.hasCompany && userData.createNewCompany

      await this.createQrMonederoUser()

      // if (!userData.hasCompany || !userData.createNewCompany) {
      //   if (this.createdUserId) {
      //     await this.createQrMonedero()
      //   }
      // } else {
      //   this.pendingUserUpdate = this.createdUserId
      // }
    },
    async handleCompanySubmit (companyData) {
      this.loading = true
      this.submittedCompany = true

      try {
        companyData.user_id = this.stationUser.id
        companyData.shared_balance = this.sharedBalance
        const response = await axios.post(`${Config.API_BASE_URL}/qr-monedero/companies`, {
          ...companyData
        })
        this.createdCompanyId = response.data.id

        // Update the user with the new company ID
        await axios.put(`${Config.API_BASE_URL}/qr-monedero/users/${this.createdUserId}`, {
          qr_monedero_company_id: this.createdCompanyId
        })

        // Finally create the QR Monedero
        // await this.createQrMonedero()
      } catch (error) {
        console.error('Error handling company submit:', error)
        this.$bvToast.toast('Error al crear la empresa', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      } finally {
        this.loading = false
      }
    },
    handleUserSelection (userId) {
      this.form.selectedUserId = userId
      this.form.selectedUserObject = this.users.find(user => user.id === userId)
    },
    async createQrMonederoUser () {
      try {
        const response = await axios.post(`${Config.API_BASE_URL}/qr-monedero/users`, this.submittedData)
        this.createdUserId = response.data.id
        this.createdUserObject = response.data

        console.log('createdUserObject:', this.createdUserObject)
        return response.data
      } catch (error) {
        console.error('Error creating QR Monedero user:', error)
        this.$bvToast.toast('Error al crear el usuario del QR Monedero', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }
    },
    async createQrMonedero () {
      this.loading = true
      try {
        const payload = {
          user_id: this.stationUser.id,
          shared_balance: this.sharedBalance,
          initialBalance: parseInt(this.form.initialBalance * 10) || 0,
          qr_monedero_user_id: this.createdUserObject ? this.createdUserObject.id : this.form.selectedUserObject.id,
          qr_monedero_company_id: this.createdUserObject ? this.createdCompanyId : this.form.selectedUserObject.qr_monedero_company_id,
          name: this.createdUserObject ? this.createdUserObject.name : this.form.selectedUserObject.name,
          email: this.createdUserObject ? this.createdUserObject.email : this.form.selectedUserObject.email
        }

        if (this.createdCompanyId) {
          payload.companyId = this.createdCompanyId
        }

        await axios.post(`${Config.API_BASE_URL}/qr-monedero/create`, payload)

        this.$router.push({ name: 'qr-monedero-list' })
        this.$bvToast.toast('QR Monedero creado correctamente', {
          title: 'Éxito',
          variant: 'success',
          solid: true
        })
      } catch (error) {
        console.error('Error creating QR Monedero:', error)
        this.$bvToast.toast('Error al crear el QR Monedero', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      } finally {
        this.loading = false
      }
    },
    handleNewUserToggle () {
      this.form.selectedUser = null
      this.submittedData = null
      this.showCompanyForm = false
      this.createdUserId = null
      this.createdUserObject = null
      this.createdCompanyId = null
      this.pendingUserUpdate = null
    },
    handleSharerBalanceToggle () {
      this.form.sharedBalance = this.sharedBalance

      if (this.sharedBalance) {
        return axios.put(`${Config.API_BASE_URL}/qr-monedero/companies/${this.createdCompanyId}`, {
          shared_balance: true
        })
      }
    },
    async loadUsers () {
      try {
        const response = await axios.get(`${Config.API_BASE_URL}/qr-monedero/users/user/${this.stationUser.id}`)
        this.users = response.data
      } catch (error) {
        console.error('Error loading users:', error)
      }
    }
  },
  created () {
    this.loadUsers()
  }
}
</script>

<style scoped>
.qr-monedero-create {
  padding: 2rem;
}
.content-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}
</style>
